import { checkIsInteger, checkEmail, checkPhoneNumber, checkIsNotNullOrWhitespace, checkIsDecimal, checkIsNotNull, checkIsDateOrNull, checkPostcode } from "../../helper/Validator";
import * as Fmt from '../../helper/Formatters'

export const GeneralInformationModelFactory = (t) => ({
    id: { value: "", isValid: true, validationError: "", validators: [] },
    customer: { name: "Customer", label: t("CustomerNo"), idkey: "GI-customer", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], headerName: t('CustomerName') },
    companyName: { label: t("CompanyName"), idkey: "GI-CompanyName", value: "", isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace] },
    address: { label: t("Address"), idkey: "GI-Address", value: "", isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace] },
    postcode: { label: t("Postcode"), idkey: "GI-Postcode", value: "", isValid: true, validationError: t("InvalidPostcode"), validators: [checkPostcode], notRequired: true},
    postcodeRegex: { label: "", idkey: "", value: "", isValid: true, validationError: "", validators: [] },
    city: { label: t("City"), idkey: "GI-City", value: "", isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace] },
    country: { label: t("Country"), idkey: "GI-Country", value: "", isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace] },
    contactPerson: { label: t("ContactPerson"), idkey: "GI-ContactPerson", value: "", isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace] },
    phoneNumber: { label: t("PhoneNumber"), idkey: "GI-PhoneNumber", value: "", isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace, checkPhoneNumber] },
    email: { label: t("Email"), idkey: "GI-Email", value: "", isValid: true, validationError: "", validators: [checkIsNotNullOrWhitespace, checkEmail] },
    mondayToFridayWorkingTime: { label: t("MondayFriday"), idkey: "GI-MondayFriday", value: 0, isValid: true, validationError: "", validators: [checkIsInteger] },
    saturdayWorkingTime: { label: t("Saturday"), idkey: "GI-Saturday", value: 0, isValid: true, validationError: "", validators: [checkIsInteger] },
    sundayWorkingTime: { label: t("Sunday"), idkey: "GI-Sunday", value: 0, isValid: true, validationError: "", validators: [checkIsInteger] },
    temperatureSpring: { label: t("Spring"), idkey: "GI-Temp-Spring", value: 0, isValid: true, validationError: "", validators: [checkIsInteger] },
    temperatureSummer: { label: t("Summer"), idkey: "GI-Temp-Summer", value: 0, isValid: true, validationError: "", validators: [checkIsInteger] },
    temperatureAutumn: { label: t("Autumn"), idkey: "GI-Temp-Autumn", value: 0, isValid: true, validationError: "", validators: [checkIsInteger] },
    temperatureWinter: { label: t("Winter"), idkey: "GI-Temp-Winter", value: 0, isValid: true, validationError: "", validators: [checkIsInteger] },
    temperatureAverage: { label: t("Average"), value: 0, isValid: true, validationError: "", validators: [] },
    humiditySpring: { label: t("Spring"), idkey: "GI-Humidity-Spring", value: 0, isValid: true, validationError: "", validators: [checkIsInteger] },
    humiditySummer: { label: t("Summer"), idkey: "GI-Humidity-Summer", value: 0, isValid: true, validationError: "", validators: [checkIsInteger] },
    humidityAutumn: { label: t("Autumn"), idkey: "GI-Humidity-Autumn", value: 0, isValid: true, validationError: "", validators: [checkIsInteger] },
    humidityWinter: { label: t("Winter"), idkey: "GI-Humidity-Winter", value: 0, isValid: true, validationError: "", validators: [checkIsInteger] },
    humidityAverage: { label: t("Average"), value: 0, isValid: true, validationError: "", validators: [] },
    altitudeAboveSeaLevel: { label: t("AltitudeAboveSeaLevel") + Fmt.getUnitInBracket("unitMFoot", t), idkey: "GI-AltitudeAboveSeaLevel", value: 0, isValid: true, validationError: "", validators: [checkIsInteger] },
    isTheAirSupllyShuttedOff: { label: t("IsTheAirSupllyShuttedOff"), idkey: "GI-IsTheAirSupllyShuttedOff", value: false, isValid: true, validationError: "", validators: [] },
    costsPerkWh: { label: t("CostsPerkWh") + Fmt.getUnitWithCurrency("powerPerHour", t), idkey: "GI-CostsPerkWh", value: 0, isValid: true, validationError: "", validators: [checkIsDecimal] },
    generalInformationComment: { label: t("GeneralInformationComment"), idkey: "GI-GeneralInformationComment", value: "", isValid: true, validationError: "", validators: [] },
    atmosphericPressure: { label: t("AtmosphericPressure") + Fmt.getUnitInBracket("pressure", t), idkey: "GI-AtmosphericPressure", value: "", isValid: true, validationError: "", validators: [] },
    numberOfEmployeesInThePlant: { label: t("NumberOfEmployeesInThePlant"), idkey: "GI-NumberOfEmployeesInThePlant", value: "", isValid: true, validationError: t("PreAuditEmployeesIntThePlantError"), validators: [checkIsInteger] },
    pneumaticTools: { label: t("PneumaticTools") + Fmt.getPercentage(), idkey: "GI-PneumaticTools", value: "", isValid: true, validationError: t("PreAuditPneumaticToolsError"), validators: [checkIsInteger] },
    pneumaticMachinery: { label: t("PneumaticMachinery") + Fmt.getPercentage(), idkey: "GI-PneumaticMachinery", value: "", isValid: true, validationError: t("PreAuditPneumaticMachineryError"), validators: [checkIsInteger] },
    process: { label: t("Process") + Fmt.getPercentage(), idkey: "GI-Process", value: "", isValid: true, validationError: t("PreAuditProcessError"), validators: [checkIsInteger] },
    isMaintanancePlanAvailable: { label: t("IsMaintanancePlanAvailable"), idkey: "GI-IsMaintanancePlanAvailable", value: false, isValid: true, validationError: "", validators: [] },
    lastMaintenance: { label: t("LastMaintenance"), idkey: "GI-LastMaintenance", value: null, isValid: true, validationError: t("PreAuditLastMaintenanceError"), notRequired: true, validators: [checkIsDateOrNull] },
    lastMaintenanceFrequency: { label: t("LastMaintenanceFrequency"), idkey: "GI-LastMaintenanceFrequency", value: "", isValid: true, validationError: t("PreAuditLastMaintenanceFrequencyError"), validators: [] },
    lastLeackageDetection: { label: t("LastLeackageDetection"), idkey: "GI-LastLeackageDetection", value: null, isValid: true, validationError: "", notRequired: true, validators: [checkIsDateOrNull] },
    heatRecoverySystem: { label: t("HeatRecoverySystem"), idkey: "GI-HeatRecoverySystem", value: false, isValid: true, validationError: "", validators: [checkIsNotNull] },
    heating: { label: t("Heating"), idkey: "GI-Heating", value: false, isValid: true, validationError: "", validators: [] },
    hotWaterGeneration: { label: t("HotWaterGeneration"), idkey: "GI-HotWaterGeneration", value: false, isValid: true, validationError: "", validators: [] },
    workingTimeYear: { label: t("HoursPerYear"), isValid: true, idkey: "GI-HoursPerYear", value: 0, validationError: "", validators: [] },
    workingTimeWeek: { label: t("HoursPerWeek"), isValid: true, idkey: "GI-HoursPerWeek", value: 0, validationError: "", validators: [] },
    cO2GridAverage: { label: t("CO2GRA") + Fmt.getUnitInBracket("co2WheightPerKwh", t), idkey: "GI-CO2GRA", value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsDecimal] },
    greenEnergy: { label: t("GreenEnergy"), idkey: "GI-GreenEnergy", value: false, isValid: true, validationError: "", validators: [] },
})

export const applyRounding = (model, country) => {
    return Object.assign({}, model, {
        atmosphericPressure: { ...model.atmosphericPressure, value: Fmt.round(model.atmosphericPressure.value, country, 3) },
        workingTimeWeek: { ...model.workingTimeWeek, value: Fmt.round(model.workingTimeWeek.value, country, 0) },
        workingTimeYear: { ...model.workingTimeYear, value: Fmt.round(model.workingTimeYear.value, country, 0) }
    })
}