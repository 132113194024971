import React, { ReactNode } from "react";
import { Select, FormControl, InputLabel, MenuItem, styled, FormControlProps, SelectChangeEvent, BaseSelectProps } from '@mui/material';
import { IDropdownItem, ModelParam, Translate } from "../../Types/Types";
import { StyledTooltip } from '../MaterialUIStyles/Styles';

const StyledFormControl = styled(FormControl)((props: FormControlProps) => ({
    '& .MuiInput-underline': {
        '&::before': {
            borderBottom: props.disabled ? '1px dotted rgba(0, 0, 0, 0.42)' : '1px solid rgba(0, 0, 0, 0.42)'
        }
    },
}));
const StyledSelect = styled(Select<number>)(() => ({
    // pointerEvents: props.disabled && 'none',
    transitionDuration: " 10s",
    paddingTop: 4,
    '& > div': {
        paddingBottom: 1,
        transitionDuration: " 10s"
    },
}));
const StyledInputLabel = styled(InputLabel)(() => ({
    // color: props.disabled && 'rgba(0, 0, 0, 0.87)',
}));

interface IProps extends BaseSelectProps<number> {
    id?: string,
    t?: Translate,
    disabled?: boolean,
    noFirstEmpty?: boolean,
    listFromDb: IDropdownItem[],
    model: ModelParam<number> | ModelParam<IDropdownItem>,
    onChange: ((event: SelectChangeEvent<number>, child: ReactNode) => void)
}

const SingleSelect2 = (props: IProps) => {
    const { listFromDb, model, onChange, t, noFirstEmpty, tabIndex, ...rest } = props;
    const id = props.id ? props.id : model.idkey;
    return (
        <StyledFormControl
            variant="standard"
            fullWidth
            style={{ transitionDuration: "10s!important" }}>
            {
                model &&
                <>
                    <StyledInputLabel
                        required={model.validators?.length > 0}
                        error={!model.isValid}
                        shrink={!!model.value} id={id + "Label"}>{model.label}
                    </StyledInputLabel>
                    <StyledTooltip title={model.isValid ? "" : model.validationError} arrow placement="bottom-end">
                        <StyledSelect
                            fullWidth
                            {...rest}
                            variant="standard"
                            id={"dd_" + id}
                            labelId={id + "_label"}
                            error={!model.isValid}
                            value={typeof (model.value) === 'number' ? model.value : model.value?.id}
                            onChange={onChange}
                            inputProps={{ id: id, tabIndex: tabIndex ?? 0 }}
                            MenuProps={{ transitionDuration: 0 }}>
                            {(noFirstEmpty == false || noFirstEmpty == undefined) ? <MenuItem id={("menuitem_" + id ?? '') + 'empty-select'} value={0}>&nbsp;</MenuItem> : null}
                            {/* {model.values && Object.keys(model.values).map(key => (<MenuItem key={key} id={("menuitem_" + id) + key + model.values[key]} value={key}>{t ? t(model.values[key]) : model.values[key]}</MenuItem>))} */}
                            {listFromDb && listFromDb.map((e: IDropdownItem) =>
                                <MenuItem key={e.id} id={("menuitem_" + id) + (e.id) + (e.value)} value={e.id} >
                                    {(e.id > 0) ? (t ? t(e.value) : (e.value)) : <b>{t ? t(e.value) : (e.value)}</b>}
                                </MenuItem>)
                            }
                        </StyledSelect>
                    </StyledTooltip>
                </>
            }
        </StyledFormControl>
    );
}

export default SingleSelect2;