import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TextFieldComponent, HeadingComponent, ButtonComponent, DatePickerComponent } from '../../../../Reusable';
import { AddLicensesModelFactory } from './AddLicensesModel';
import { API } from '../../../../../helper/ApiHelper';
// @ts-expect-error not converted yet
import { updateModel, extractValuesFromModel } from '../../../../../helper/Mappers';
import { IdParam } from '../../../../Types/Types';

interface IProps {
    loadExecutor: () => void
}

export default function AddLicenses(props: IProps) {
    const { loadExecutor } = props;

    const { t } = useTranslation();
    const { id } = useParams<IdParam>();

    const [mainModel, setMainModel] = useState(AddLicensesModelFactory(t));
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open) setMainModel(AddLicensesModelFactory(t))
    }, [open])

    const addLicensesUrl = () => process.env.REACT_APP_DEVFESSSERVICE_BASE + "executor-management/executor/" + id + "/add-licenses";

    const addLicenses = async () => {
        await API.put(addLicensesUrl(), extractValuesFromModel(mainModel));
        setOpen(false);
        loadExecutor();
    }

    return (
        <React.Fragment>
            <Dialog
                aria-labelledby="transition-modal-title"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
            >
                <DialogTitle>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <h3 style={{ margin: "0px" }}>{t('AddLicenses')}</h3>
                        </Grid>
                        <Grid item>
                            <IconButton color="inherit" onClick={() => setOpen(false)} size="large">
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <HeadingComponent value={t('AvailableLicenses')} size="h6" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent
                                t={t}
                                model={mainModel.standardLeakageDetectionsQnt}
                                onChange={e => updateModel("standardLeakageDetectionsQnt", e.target.value, mainModel, setMainModel)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent
                                t={t}
                                model={mainModel.standardFullAuditQnt}
                                onChange={e => updateModel("standardFullAuditQnt", e.target.value, mainModel, setMainModel)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <HeadingComponent value={t('ActiveSubscriptions')} size="h6" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePickerComponent
                                model={mainModel.proLeakageExpirationTime}
                                onChange={(e: any) => updateModel("proLeakageExpirationTime", e, mainModel, setMainModel)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePickerComponent
                                model={mainModel.proAuditExpirationTime}
                                onChange={(e: any) => updateModel("proAuditExpirationTime", e, mainModel, setMainModel)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item>
                            <ButtonComponent value={t('Cancel')} color="secondary" onChange={() => setOpen(false)} id="executor-licenses-cancel"></ButtonComponent>
                        </Grid>
                        <Grid item>
                            <ButtonComponent value={t('Save')} color="primary" onChange={addLicenses} id="executor-licenses-save"></ButtonComponent>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <ButtonComponent
                className="btn-no-left-margin"
                value={t("AddLicenses")}
                color="primary"
                onChange={() => setOpen(true)}
                id="btn-executor_add_licenses"
            />
        </React.Fragment>
    );
}
