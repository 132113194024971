import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { TextFieldComponent, ButtonComponent, SwitchComponent } from '../../../../Reusable';
import { ExecutorDocumentationModel, IExecutorDocumentationModel } from './ExecutorDocumentationModel';
// @ts-expect-error not converted yet
import * as Mapper from '../../../../../helper/Mappers';
// @ts-expect-error not converted yet
import * as Validator from '../../../../../helper/Validator';
import { OpenGlobalSnackbar } from '../../../../../helper/GlobalVariables';
import { API } from '../../../../../helper/ApiHelper';

interface IProps {
    open: boolean,
    refresh: () => void,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}


export default function AddDocumentation(props: IProps) {
    const { open, setOpen, refresh } = props;
    const execUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "executor-management/executor/";

    const { t } = useTranslation();
    const fileInput = React.useRef<HTMLInputElement>(null);

    const [mainModel, setMainModel] = useState<IExecutorDocumentationModel>(ExecutorDocumentationModel(t));
    const [file, setFile] = useState<File | null>(null);

    useEffect(() => {
        if (open)
            getData()
    }, [open]);

    const getData = () => {
        setMainModel(ExecutorDocumentationModel(t));
    }

    const updateModel = (property: string, value: any) => {
        const updatedModel = Mapper.updateModel(property, value, mainModel);
        setMainModel({ ...updatedModel });
    }

    const save = async () => {
        const [isValid, validatedModel] = Validator.checkAndValidateModel(mainModel, true);
        setMainModel(validatedModel);
        if (isValid) {
            const data = new FormData();
            data.append('file', file != null ? file : "");
            data.append('name', mainModel.name.value);
            data.append('url', mainModel.url.value);
            data.append('isBlob', mainModel.isBlob.value ? "true" : "false");
            await API.post(`${execUrl}documentation`, data);
            setOpen(false);
            refresh();
        }
        else {
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
        }
    }

    const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.files != null) {
            if (e.target.files.length > 0) {
                const fileVariable = e.target.files[0];
                setFile(fileVariable);
            }
        }
    };

    return (
        <Dialog
            aria-labelledby="transition-modal-title"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
        >
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <h3 style={{ margin: "0px" }}>{t('UploadDocumentation')}</h3>
                    </Grid>
                    <Grid item>
                        <IconButton color="inherit" onClick={() => setOpen(false)} size="large">
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container direction="column">
                    <Grid item xs={12}>
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <Typography component="div">
                                    <Grid component="label" container alignItems="center" spacing={1}>
                                        <Grid item>{t("Link")}</Grid>
                                        <Grid item>
                                            <SwitchComponent
                                                checked={mainModel.isBlob.value}
                                                onChange={e => updateModel("isBlob", e.target.checked)}
                                                name="switchIsBlob"
                                                color="primary"
                                            />
                                        </Grid>
                                        <Grid item>{t("File")}</Grid>
                                    </Grid>
                                </Typography>
                            </Grid>
                            {mainModel.isBlob.value === false ?
                                <React.Fragment>
                                    <Grid item>
                                        <TextFieldComponent
                                            t={t}
                                            model={mainModel.name}
                                            onChange={e => updateModel("name", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextFieldComponent
                                            t={t}
                                            model={mainModel.url}
                                            onChange={e => updateModel("url", e.target.value)}
                                        />
                                    </Grid>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Grid item>
                                        <TextFieldComponent
                                            t={t}
                                            model={mainModel.name}
                                            onChange={e => updateModel("name", e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {file?.name}
                                    </Grid>
                                    <ButtonComponent
                                        value={t("ChooseDocument")}
                                        color="primary"
                                        onChange={() => { if (fileInput.current != null) fileInput.current.click() }}
                                        id="btn_upload-executor-logo"
                                    />
                                    <input
                                        ref={fileInput}
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={selectFile}
                                    />
                                </React.Fragment>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        <ButtonComponent value={t('Cancel')} color="secondary" onChange={() => setOpen(false)}></ButtonComponent>
                    </Grid>
                    <Grid item>
                        <ButtonComponent value={t('Save')} color="primary" onChange={save}></ButtonComponent>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}