import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { API } from '../../helper/ApiHelper';
import { ButtonComponent, HeadingComponent, SingleSelectComponent } from '../Reusable/';
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ProjectStore, ExecutorStore, UserStore } from '../../store';
import * as Consts from '../../helper/ConstantRepository';
import { ProjectSettingsTrigger } from '../../store/ActionTrigger';

export default function ReopenProjectDialog(props) {
    const { open, setOpen, projectInfo } = props;

    const execUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "executor-management/executor/";
    const projUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "project-management/project/";

    const { t } = useTranslation();
    let { id } = useParams();

    const [mainModel, setMainModel] = useState({});
    const [licenseTypeList, setLicenseTypeList] = useState([]);

    useEffect(() => {
        if (open === true && id != undefined && projectInfo.projectInfo?.status == Consts.EXPIRED) {
            getProjectLicences();
        }
    }, [open]);

    const getProjectLicences = () => {
        API.get(execUrl + ExecutorStore.executorSelection.value + "/licenses-types").then(resp => {
            if (projectInfo.projectInfo?.licenceTypeId == Consts.FullAudit)
                resp = resp.filter(el => el.id == Consts.FullAudit);
            setLicenseTypeList(resp);
        });
    }

    const updateModel = (value) => {
        mainModel.licenceTypeId = value;
        setMainModel({ ...mainModel });
    }

    const reopen = () => {
        let postModel = { licenceTypeId: mainModel.licenceTypeId };
        API.post(projUrl + id + "/reopen", postModel).then(closeDialogAndReload);
    }

    const finishProject = () => {
        API.post(projUrl + id + "/finish").then(closeDialogAndReload);
    }

    const closeDialogAndReload = () => {
        setOpen(false);
        ProjectStore.initProjectInfo(id, true);
        ProjectSettingsTrigger.reload();
    }

    const getDialogContent = () => {
        if (projectInfo.projectInfo?.status == Consts.EXPIRED) {
            return (
                <Grid container direction="column">
                    <Grid item>
                        <HeadingComponent value={t('PleaseSelectALicence')} size="h6" />
                    </Grid>
                    {licenseTypeList.length === 0 ?
                        <Grid item>
                            <HeadingComponent value={t('NoAvailableLicences')} size="h6" />
                        </Grid>
                        :
                        <React.Fragment>
                            <Grid item xs={12} md={6}>
                                <SingleSelectComponent
                                    model={{ isValid: true, value: mainModel.licenseTypeId }}
                                    t={t}
                                    listFromDb={_.cloneDeep(licenseTypeList).map(item => {
                                        item.value = t(item.value) + ` (${(item.count > -1 ? item.count : t("Pro"))})`;
                                        return item;
                                    })}
                                    onChange={e => updateModel(e.target.value)}
                                    noFirstEmpty={true}
                                />
                            </Grid>
                            <Grid item>
                                <HeadingComponent value={t('or')} size="h6" />
                            </Grid>
                        </React.Fragment>
                    }
                    <Grid item>
                        <ButtonComponent
                            className="btn-no-left-margin"
                            value={t("BuyLicenses")}
                            color="primary"
                            onChange={() => window.open("https://www.festo.com/appworld/fess-portal-pneumatics-sustainability-check-subscription-license.html", "_blank")}
                        />
                    </Grid>
                </Grid>
            )
        }
        else if (projectInfo.projectInfo?.status == Consts.RUNNING) {
            return <HeadingComponent value={t('FinishProjectApproval')} size="h6" />
        }
        else if (projectInfo.projectInfo?.status == Consts.FINISHED) {
            return (
                <React.Fragment>
                    <HeadingComponent value={t('ReopenProjectApproval')} size="h6" />
                    {projectInfo.projectInfo?.isProjectExpired &&
                        <HeadingComponent value={t('ProjectIsExpiredWarning')} size="h6" />
                    }
                </React.Fragment>
            )
        }
    }

    const resetFinishCount = () => {
        API.get(projUrl + id + "/resetfinishcount");
    }

    return (
        <Dialog open={open}>
            <DialogTitle>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        <IconButton color="inherit" size="small" onClick={() => setOpen(false)}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {getDialogContent()}
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                        {UserStore?.user?.value?.userRights?.isSuperAdmin &&
                            <ButtonComponent value={t('ResetFinishCount')} id="DialogResetFinish" color="secondary" onChange={resetFinishCount}></ButtonComponent>
                        }
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justifyContent="flex-end">
                            <Grid item><ButtonComponent value={t('Cancel')} id="Dialogcancel" color="secondary" onChange={() => setOpen(false)}></ButtonComponent></Grid>
                            {projectInfo.projectInfo?.status == Consts.EXPIRED ?
                                <Grid item><ButtonComponent value={t('Reopen')} id="DialogReopen" color="primary" onChange={reopen}></ButtonComponent></Grid>
                                :
                                <Grid item><ButtonComponent value={t('Yes')} id="DialogReopen" color="primary" onChange={finishProject}></ButtonComponent></Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}