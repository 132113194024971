import React from 'react';
import { Grid, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { TextFieldComponent, HeadingComponent, ButtonComponent } from '../../../../Reusable';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { API } from '../../../../../helper/ApiHelper';
import { FormatDate } from '../../../../../helper/DateHelper';
import AddLicenses from './AddLicenses';
import { UserStore } from '../../../../../store';
import { HasUserRole } from '../../../../../helper/AuthorizationHelper/AuthHelper';
import * as Roles from '../../../../../helper/AuthorizationHelper/AuthConstatants';
import LicenseRequest from '../LicenseRequest/LicenseRequest';
import * as Consts from '../../../../../helper/ConstantRepository';
import MoveLicense from './MoveLicense';
import { IdParam } from '../../../../Types/Types';
import { IFullExecutorData } from '../AddEditExecutor';

interface IProps { 
    loadExecutor: () => void,
    setIsMainView:  React.Dispatch<React.SetStateAction<boolean>>,
    loadExistingData: () => void,
    mainModel :IFullExecutorData
}
export default function ExecutorLicenses(props: IProps) {
    const { mainModel, loadExistingData, setIsMainView, loadExecutor } = props;

    const syncUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "licenses/sync/";

    const { t } = useTranslation();
    const { id } = useParams<IdParam>();

    const refreshLicenses = () => {
        API.get(syncUrl + id).then(() => loadExistingData())
    }

    return (
        <Grid container spacing={3} direction="column" className="licensing-information-page">
            <Grid item xs={12}>
                <HeadingComponent value={t("LicensingInformation")} size="h6" />
                <HeadingComponent value={mainModel.subsidiaryFestoName ?? ""} size="h4" />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextFieldComponent
                    disabled
                    t={t}
                    model={{ isValid: true, label: t("TenantId"), value: mainModel.tenantId ?? "", idkey: "", validationError: "", validators: [] }}
                />
                {mainModel.festoExecutor === true ?
                    <LicenseRequest executorModel={mainModel} />
                    :
                    <ButtonComponent
                        className="btn-no-left-margin"
                        value={t("BuyLicenses")}
                        color="primary"
                        onChange={() => window.open('https://www.festo.com/appworld/fess-portal-pneumatics-sustainability-check-subscription-license.html')}
                        id="executor-buy-licenses"
                    />
                }
                <ButtonComponent
                    className="btn-no-left-margin"
                    value={t("RefreshLicenses")}
                    color="primary"
                    onChange={refreshLicenses}
                    id="executor-refresh-licenses"
                />
                {HasUserRole([Roles.SuperAdmin], UserStore.user.value) &&
                    <AddLicenses
                        loadExecutor={loadExecutor}
                    />
                }
            </Grid>
            <Grid item xs={12}>
                <HeadingComponent value={t("AvailableLicenses")} size="h6" />
            </Grid>
            <Grid item xs={12} md={6}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("LicenseType")}</TableCell>
                                <TableCell>{t("Availability")}</TableCell>
                                <TableCell>{t("Move")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{t("LicenseLeakageDetection")}</TableCell>
                                <TableCell id="td-standardLeakageDetectionsQnt">{mainModel.standardLeakageDetectionsQnt}</TableCell>
                                <TableCell width="1%">
                                    <MoveLicense
                                        licenseId={Consts.StandardLeakageDetection}
                                        refreshLicenses={refreshLicenses}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t("LicenseFullAudit")}</TableCell>
                                <TableCell id="td-standardFullAuditQnt">{mainModel.standardFullAuditQnt}</TableCell>
                                <TableCell width="1%">
                                    <MoveLicense
                                        licenseId={Consts.StandardFullAudit}
                                        refreshLicenses={refreshLicenses}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
                <HeadingComponent value={t("ActiveSubscriptions")} size="h6" />
            </Grid>
            <Grid item xs={12} md={6}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: '30%' }}>{t("SubscriptionType")}</TableCell>
                                <TableCell align="center">{t("Availability")}</TableCell>
                                <TableCell>{t("ExpirationDate")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{t("SubscriptionLeakageDetection")}</TableCell>
                                <TableCell align="center" id="td-professionalLeakageDetection-check">{mainModel.professionalLeakageDetection === true ? "\u2713" : ""}</TableCell>
                                <TableCell id="td-professionalLeakageDetection-date">{mainModel.professionalLeakageDetection === true && FormatDate(mainModel.proLeakageExpirationTime)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{t("SubscriptionFullAudit")}</TableCell>
                                <TableCell align="center" id="td-professionalFullAudit-check">{mainModel.professionalFullAudit === true ? "\u2713" : ""}</TableCell>
                                <TableCell id="td-professionalFullAudit-date">{mainModel.professionalFullAudit === true && FormatDate(mainModel.proAuditExpirationTime)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container direction="row" justifyContent="flex-end">
                    <Grid item>
                        <ButtonComponent
                            value={t("Cancel")}
                            color="secondary"
                            onChange={() => setIsMainView(true)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
