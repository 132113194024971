import { ModelParam, Translate } from "../../../../Types/Types";

export interface IAddLicensesModelFactory {
    standardLeakageDetectionsQnt: ModelParam<string>,
    standardFullAuditQnt:  ModelParam<string>,
    proAuditExpirationTime:  ModelParam<null>,
    proLeakageExpirationTime: ModelParam<null>,
}

export const AddLicensesModelFactory = (t: Translate) : IAddLicensesModelFactory => ({
    standardLeakageDetectionsQnt: { value: "0", idkey: "Exec-AddLicense_StandardLeakageDetectionsQnt", label: t("LicenseLeakageDetection"), isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    standardFullAuditQnt: { label: t("LicenseFullAudit"), idkey: "Exec-AddLicense_StandardFullAuditQnt", value: "0", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    proAuditExpirationTime: { label: t("SubscriptionFullAudit"), idkey: "Exec-AddLicense_ProAuditExpirationTime", value: null, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    proLeakageExpirationTime: { label: t("SubscriptionLeakageDetection"), idkey: "Exec-AddLicense_ProLeakageExpirationTime", value: null, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] }
})
