export const GenerateBreadcrumbsFromPath = (path) => {
    const START_POSITION = 4;
    var crumbs = path.split("/").slice(START_POSITION);
    return InsertProjectsBreadcrumb(crumbs);
}

export const PathKeyNamePairs = {
    //Path : Translation Key
    AN: "AirNetwork",
    PS: "ProjectSettings",
    GI: "GeneralInformation",
    CAG: "CompressedAirGeneration",
    MACAC: "MA/CACTabName",
    LA: "LeakageAnalysis",
    LDAR: "LeakageDetectionAndRepair",
    CS: "CostSavings",
    AQ: "AirQuality",
    order: "Cart",
    validate: "ValidateProject",
    project: "Project",
    projects: "Projects",
    requests: "Requests",
    projectsandrequests: "ProjectsAndRequests"
}

export const GetRoutingLink = (breadCrumb, path) => {
    if (IsRoutingPathValid(breadCrumb)) {
        return "/" + GetRoutingPath(breadCrumb, path);
    }
}

export const BreadCrumbExistsInPath = (breadCrumb, path) => path.indexOf(breadCrumb) != -1;

export const GetRoutingPath = (breadCrumb, path) => {
    if (breadCrumb.indexOf('project') != -1)
        return path.split("/").slice(1, 3).join("/") + "/projectsandrequests"

    return path.split("/").splice(1, path.split("/").indexOf(breadCrumb)).join("/");
}

export const InsertProjectsBreadcrumb = (breadCrumbs) => {
    if (BreadCrumbExistsInPath("projectsandrequests", breadCrumbs))
        return breadCrumbs;

    var copyBreadcrumbs = [...breadCrumbs]
    copyBreadcrumbs.splice(0, 0, "projectsandrequests")
    return copyBreadcrumbs;
}

export const IsRoutingPathValid = (breadcrumb) => {
    var isValid = true;

    if (!isNaN(breadcrumb)) isValid = false;
    else if (exceptionPaths.some(e => e.toLocaleLowerCase() == breadcrumb.toLocaleLowerCase())) isValid = false

    return isValid;
}

const exceptionPaths =
    [
        "SettingsPanel",
        "AddEditCompressor",
        "IndividualDryer",
        "AirQualityMeasurement",
        "DistributionLine", "AirReceiver",
        "PressureDropAnalysis",
        "Application",
        "Leakage",
        "AddEditExecutor",
        "Project"
    ]
