import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../../../helper/ApiHelper';
import { OpenGlobalSnackbar } from '../../../../helper/GlobalVariables';
import { ButtonComponent, DatePickerComponent, HeadingComponent } from '../../../Reusable';
import { ReportToExcel } from './Template/ReportExcelTemplate';
import { ExecutorStore, IExecutorSelection } from "../../../../store/ExecutorStore";
import ReplacementPartsReportToExcel from './Template/ReplacementPartsReportExcelTemplate';
import { AutocompleteDropdown } from '../../../Reusable';
import { IReportFactory, ReportFactory } from './ReportModel'
// @ts-expect-error not converted yet
import * as Mapper from '../../../../helper/Mappers';
import { IBase, IDropdownItem } from '../../../Types/Types';

export interface ICO2Report {
    // Project Information:
    pi_nr: string;
    pi_executor: string;
    pi_projectName: string;
    pi_customerNumber: string;
    pi_companyName: string;
    pi_address: string;
    pi_postcode: string;
    pi_city: string;
    pi_phoneNumber: string;
    pi_country: string;
    pi_serviceType: string[] | string;
    pi_status: string;
    pi_createdDate: string;
    pi_currency: string;
    // Leakage Level And Costs Per Year:
    la_averageAirFlow: number;
    la_electricity: number;
    la_air: number;
    la_co2: number;
    la_energyCosts: number;
    la_totalCosts: number;
    // Detected Leakages:
    ldar_detected_quantity: number;
    ldar_detected_flowTotal: number;
    ldar_detected_airVolume: number;
    ldar_detected_percentage: number;
    ldar_detected_electricityConsumption: number;
    ldar_detected_co2: number;
    ldar_detected_costs: number;
    // Repaired Leakages:
    ldar_repaired_quantity: number;
    ldar_repaired_flowTotal: number;
    ldar_repaired_airVolume: number;
    ldar_repaired_percentage: number;
    ldar_repaired_electricityConsumption: number;
    ldar_repaired_co2: number;
    ldar_repaired_costs: number;
    // Open Leakages:
    ldar_open_quantity: number;
    ldar_open_flowTotal: number;
    ldar_open_airVolume: number;
    ldar_open_percentage: number;
    ldar_open_electricityConsumption: number;
    ldar_open_co2: number;
    ldar_open_costs: number;
    // Repair time in minutes
    ldar_repairTimePlanned: number;
    ldar_repairTimeRepaired: number;
    // Cumulated Total Savings:
    cs_electricityConsuption: number;
    cs_airConsumption: number;
    cs_co2: number;
    cs_energyCosts: number;
    cs_totalCosts: number;
}

export interface IReplacementPartsReport {
    nr: string;
    companyName: string | null;
    customer: string | null;
    address: string | null;
    postcode: string | null;
    city: string | null;
    country: string | null;
    createdDate: string | null;
    status: string;
    executor: string;
    serviceType: string[];
    replacementPartDTOs: IReplacementPartDTO[];
}
export interface IReplacementPartDTO  extends IBase {
    id: number;
    no: number;
    partNumber: string | null;
    typeCode: string | null;
    component: IDropdownItem;
    manufacturer: string | null;
    replaceWithDifferent: boolean;
    newPartNumber: string | null;
    newTypeCode: string | null;
    newManufacturer: string | null;
    orderSparePart: boolean;
    quantity: number;
    guid: string | null;
    parentMeasurementCode: number | null;
}

export default function ReportGeneration() {
    const { t } = useTranslation();
    const rpgUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "report-generation/generate";
    const replacementPartReportUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "report-generation/replacement-parts-generate";

    const [model, setModel] = useState<IReportFactory>(ReportFactory(t));
    const [excelCO2, setExcelC02] = useState<{ data: ICO2Report[], show: boolean }>({ data: [], show: false });
    const [excelReplacement, setExcelReplacement] = useState<{ data: IReplacementPartsReport[], show: boolean }>({ data: [], show: false });
    const [userExecutors, setUserExecutors] = useState<IExecutorSelection[]>([]);
    const [chosenExecutor, setChosenExecutor] = useState<number | undefined>(undefined);

    React.useEffect(() => {
        const execSub1 = ExecutorStore.executorList.subscribe(executors => setUserExecutors(executors));
        const execSub2 = ExecutorStore.executorSelection.subscribe(executorId => setChosenExecutor(executorId));

        return () => {
            execSub1.unsubscribe();
            execSub2.unsubscribe();
        }
    }, []);

    const fetchC02Data = () => {
        try {
            API.get(rpgUrl + "/" + dateToString(model.fromCO2Date.value) + "/" + dateToString(model.toCO2Date.value)).then(res => {
                setExcelC02({ data: res, show: true });
            })
        } catch (error) {
            OpenGlobalSnackbar("danger", t('error'));
        }
        setExcelC02({ ...excelCO2, show: false });
    };

    const fetchReplacementData = () => {
        try {
            API.get(replacementPartReportUrl + "/" + dateToString(model.fromReplacementDate.value) + "/" + dateToString(model.toReplacementDate.value) + "/" + chosenExecutor).then(res => {
                setExcelReplacement({ data: res, show: true });
            })
        } catch (error) {
            OpenGlobalSnackbar("danger", t('error'));
        }
        setExcelReplacement({ ...excelReplacement, show: false });
    };

    const dateToString = (date: Date) => {
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    };

    return (
        <div>
            <Grid container spacing={3} direction="row">
                <Grid item xs={12}>
                    <HeadingComponent value={t("ReportGeneration")} size="h6" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DatePickerComponent
                        t={t}
                        model={model.fromCO2Date}
                        onChange={(e: any) => Mapper.updateModel("fromCO2Date", e, model, setModel)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DatePickerComponent
                        t={t}
                        model={model.toCO2Date}
                        onChange={(e: any) => Mapper.updateModel("toCO2Date", e, model, setModel)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item>
                            {excelCO2.show ? <ReportToExcel data={excelCO2.data}></ReportToExcel> : <></>}
                        </Grid>
                        <Grid item>
                            <ButtonComponent id="btn_Generate-Report" onChange={fetchC02Data} value={t("GenerateCO2Report")} color="primary" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <HeadingComponent value={t("ReplacementPartReport")} size="h6" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DatePickerComponent
                        t={t}
                        model={model.fromReplacementDate}
                        onChange={(e: any) => Mapper.updateModel("fromReplacementDate", e, model, setModel)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DatePickerComponent
                        t={t}
                        model={model.toReplacementDate}
                        onChange={(e: any) => Mapper.updateModel("toReplacementDate", e, model, setModel)}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    {
                        userExecutors.length > 0 &&
                        <Grid item className="report-executorSelect">
                            <AutocompleteDropdown
                                model={{ label: t("Executor"), idkey: "report-header-executorselect", value: chosenExecutor, isValid: true }}
                                onChange={(e: { id: React.SetStateAction<number | undefined>; } | null) => e != null ? setChosenExecutor(e.id) : ""}
                                options={userExecutors}
                                optionName="value"
                                optionKey="id"
                                portaldisable={true}
                                TranslateFirstTwo={true}
                            />
                        </Grid>
                    }
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item>
                            {excelReplacement.show ? <ReplacementPartsReportToExcel data={excelReplacement.data}></ReplacementPartsReportToExcel> : <></>}
                        </Grid>
                        <Grid item>
                            <ButtonComponent id="btn_Generate-ReplacementPart-Report" onChange={fetchReplacementData} value={t("GenerateReplacementPartReport")} color="primary" />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}