import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TextFieldComponent, ButtonComponent, SingleSelectComponent, CheckboxComponent, ModalComponent } from '../../../../Reusable';
import { LicenseRequestModel, LicenseSelections, LicenseQuantitySelections, ILicenseRequestModelFactory } from './LicenseRequestModel';
import { API } from '../../../../../helper/ApiHelper';
// @ts-expect-error not converted yet
import { updateModel, updateSelectModel, extractValuesFromModel } from '../../../../../helper/Mappers';
// @ts-expect-error not converted yet
import { checkAndValidateModel } from '../../../../../helper/Validator';
import { OpenGlobalSnackbar } from '../../../../../helper/GlobalVariables';
import { ExecutorStore } from '../../../../../store';
import * as Consts from '../../../../../helper/ConstantRepository';
import { IDropdownItem, IdParam } from '../../../../Types/Types';
import { IFullExecutorData } from '../AddEditExecutor';
import { IExecutorSelection } from '../../../../../store/ExecutorStore';

interface IProps {
    executorModel : IFullExecutorData
}

export default function LicenseRequest(props : IProps) {
    const { executorModel } = props;

    const { t } = useTranslation();
    const { id } = useParams<IdParam>();
    const licenseSelections = LicenseSelections(t);
    const licenseQuantitySelections = LicenseQuantitySelections(t);

    const [mainModel, setMainModel] = useState<ILicenseRequestModelFactory>(LicenseRequestModel(t));
    const [open, setOpen] = useState(false);
    const [executorList, setExecutorList] = useState<IExecutorSelection[]>([]);
    const [modalModel, setModalModel] = useState({
        open: false,
        message: t("TheOrderHasBeenSentToHeadquarters"),
        okText: t("Ok"),
        clickOk: async (): Promise<void> => setOpen(false)
    });

    useEffect(() => {
        const execSub = ExecutorStore.executorList.subscribe(exList => {
            setExecutorList(exList.filter(x => x.id > 0));
        })
        return () => execSub.unsubscribe();
    }, []);

    useEffect(() => {
        if (open) {
            const licenseRequestModel = LicenseRequestModel(t);
            setMainModel(licenseRequestModel)
        }
    }, [open])

    const licenseRequestUrl = () => process.env.REACT_APP_DEVFESSSERVICE_BASE + "executor-management/executor/" + id + "/request-internal-licenses";

    const requestLicense = async () => {
        const [isValid, validatedModel] = checkAndValidateModel(mainModel, true);
        setMainModel(validatedModel);
        if (isValid) {
            const extractedModel = extractValuesFromModel(validatedModel);
            extractedModel.license = extractedModel.license.id;
            extractedModel.quantity = extractedModel.quantity.id;
            extractedModel.executorReceiverId = extractedModel.executorReceiverId?.id;
            await API.post(licenseRequestUrl(), extractedModel);

            modalModel.open = true;
            setModalModel({ ...modalModel });
        }
        else {
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
        }
    }

    const updateLicense = (value : number) => {
        const model = mainModel;
        if (value === Consts.ProfessionalLeakageDetection || value === Consts.ProfessionalFullAudit) {
            model.quantity.value = licenseQuantitySelections.find(x => x.id === 1) as IDropdownItem;
            model.quantity.disabled = true;
        }
        else {
            model.quantity.disabled = false;
        }
        updateSelectModel("license", value, licenseSelections, model, setMainModel);
    }

    const updateReceiverCheckbox = (value: boolean) => {
        mainModel.executorReceiverIsDifferent.value = value;
        if (value === false)
            mainModel.executorReceiverId.value = null;
        setMainModel({ ...mainModel });
    }

    const getLicenseSelections = () => {
        if (executorModel.licenseType?.id === Consts.LeakageDetection)
            return licenseSelections.filter(x => x.id === Consts.StandardLeakageDetection || x.id === Consts.ProfessionalLeakageDetection);
        else
            return licenseSelections;
    }

    return (
        <React.Fragment>
            <ModalComponent model={modalModel} />
            <Dialog
                aria-labelledby="transition-modal-title"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
            >
                <DialogTitle>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <h3 style={{ margin: "0px" }}>{t('InternalLicensePurchase')}</h3>
                        </Grid>
                        <Grid item>
                            <IconButton color="inherit" onClick={() => setOpen(false)} size="large">
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container direction="column" spacing={3}>
                        <Grid item xs={12}>
                            <CheckboxComponent
                                model={mainModel.executorReceiverIsDifferent}
                                onChange={e => updateReceiverCheckbox(e.target.checked)}
                            />
                        </Grid>
                        {mainModel.executorReceiverIsDifferent.value === true &&
                            <Grid item xs={12}>
                                <SingleSelectComponent
                                    model={mainModel.executorReceiverId}
                                    t={t}
                                    listFromDb={executorList}
                                    onChange={(e: { target: { value: any; }; }) => updateSelectModel("executorReceiverId", e.target.value, executorList, mainModel, setMainModel)}
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <TextFieldComponent
                            t = {t}
                                model={mainModel.customerPurchaseOrderNumber}
                                onChange={e => updateModel("customerPurchaseOrderNumber", e.target.value, mainModel, setMainModel)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SingleSelectComponent
                                model={mainModel.license}
                                t={t}
                                listFromDb={getLicenseSelections()}
                                onChange={(e: { target: { value: any; }; }) => updateLicense(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SingleSelectComponent
                                model={mainModel.quantity}
                                t={t}
                                disabled={mainModel.quantity.disabled}
                                listFromDb={licenseQuantitySelections}
                                onChange={(e: { target: { value: any; }; }) => updateSelectModel("quantity", e.target.value, licenseQuantitySelections, mainModel, setMainModel)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item>
                            <ButtonComponent value={t('Cancel')} color="secondary" onChange={() => setOpen(false)} id="LicenseRequest-cancel"></ButtonComponent>
                        </Grid>
                        <Grid item>
                            <ButtonComponent value={t('Order')} color="primary" onChange={requestLicense} id="LicenseRequest-order"></ButtonComponent>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <ButtonComponent
                className="btn-no-left-margin"
                value={t("InternalLicensePurchase")}
                color="primary"
                onChange={() => setOpen(true)}
                id="executor-InternalLicensePurchase"
            />
        </React.Fragment >
    );
}
