import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MeasurementId from '../../../components/MeasurementId/MeasurementId';
import ReplacementParts from '../../../components/ReplacementParts/ReplacementParts';
import ImageViewer from '../../../components/ImageViewer/ImageViewer';
import { TextareaAutosize, Grid, Tooltip, IconButton } from '@mui/material';
import { useParams, useHistory, useLocation } from "react-router-dom";
import { TextFieldComponent, NumberInputFieldComponent, CheckboxComponent, ButtonComponent, HeadingComponent, DatePickerComponent, SingleSelectComponent, AutocompleteComponent, ListPreviousNext } from '../../../components/Reusable/'
import * as Mapper from '../../../helper/Mappers'
import { API } from '../../../helper/ApiHelper';
import { CompressorModelFactory } from './CompressorModel'
import * as ImageBlobConstants from '../../ImageViewer/Constants';
import { OpenGlobalSnackbar, CompressorTypes, CompressorControlTypes, ScrollerToTag } from '../../../helper/GlobalVariables';
import { ExecutorStore, DirtyStore } from '../../../store';
import * as InputRestrictions from '../../../helper/InputRestrictions';
import { Search } from '@mui/icons-material';
import CompressorSearchDialog from './CompressorSearchDialog';
import { checkAndValidateModel } from "../../../helper/Validator";

export default function EditIndividualCompressorInformation(props) {
    const { denyEdit } = props;
    const cagUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "compressed-air-generation";
    const { t } = useTranslation();
    let { country, lng, id, cid } = useParams();
    let history = useHistory();
    const location = useLocation();
    const [flagForListRedraw, setFlagForListRedraw] = useState(0);
    const [compressorModel, setCompressorModel] = useState(CompressorModelFactory(t))
    const [replacementPartList, setReplacementPartList] = useState([]);
    const [executorUsersOptions, setexecutorUsersOptions] = useState([]);
    const [compressorSearchOpen, setCompressorSearchOpen] = useState(false);

    useEffect(() => {
        Mapper.updateExistingModelFormattingLabels(compressorModel, CompressorModelFactory(t), setCompressorModel);
    }, [t]);

    useEffect(() => {
        if (!!cid && cid > 0) loadIndCompressorDetails(cid);
        if (cid == 0) loadCleanPage();
    }, [cid])

    useEffect(() => {
        let execSub = ExecutorStore.executorSettings.subscribe(executorSettings => {
            if (executorSettings && executorSettings.executorUsers) setexecutorUsersOptions(executorSettings.executorUsers);
        })
        return () => execSub.unsubscribe();
    }, []);

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const loadIndCompressorDetails = (cid) => {
        API.get(`${cagUrl}/${id}/compressor/${cid}`).then(resp => {
            let mappedModel = Mapper.mapDataToModelValues(resp.compressorInformation, CompressorModelFactory(t));
            if (location.hash.includes('validate')) {
                let [, validatedModel] = checkAndValidateModel(mappedModel);
                mappedModel = validatedModel;
            }
            setCompressorModel({ ...mappedModel });
            setReplacementPartList([...resp.compressorInformation.replacementPartList]);
            ScrollerToTag();
        })
    }

    const updateModelHandler = (property, value) => {
        let updatedModel = Mapper.updateModel(property, value, compressorModel)
        setCompressorModel({ ...updatedModel });
        DirtyStore.setIsDirty(postForm);
    }

    const updateSelectModel = (element, value, values) => {
        compressorModel[element].value = values.find(x => x.id == value);
        compressorModel[element].isValid = true;
        compressorModel[element].validators.map((v) => { if (v(value) === false) compressorModel[element].isValid = false; });
        setCompressorModel({ ...compressorModel });
        DirtyStore.setIsDirty(postForm);
    }

    const loadCleanPage = () => {
        let newModel = CompressorModelFactory(t);
        newModel.guid.value = Mapper.getNewGuid();
        setCompressorModel(newModel);
        setReplacementPartList([]);
        setFlagForListRedraw(flagForListRedraw + 1);
    };

    const copyForm = async () => {
        Mapper.copyForm(compressorModel, setCompressorModel);
        replacementPartList.forEach(x => x.id = 0);
        scrollTop();
    }

    const validateFormModel = () => {
        let isValid = true;
        Object.values(compressorModel).forEach(m => {
            if (m.validators.length > 0)
                m.validators.forEach(v => {
                    if (v.length == 1) {
                        if (v(m.value) === false) {
                            isValid = false;
                            m.isValid = false;
                        }
                    } else if (v.length == 2) {
                        if (v(m.value, compressorModel) === false) {
                            isValid = false;
                            m.isValid = false;
                        }
                    }
                });
        });
        setCompressorModel({ ...compressorModel });
        return isValid;
    }

    const postForm = (goBack) => {
        let isValid = validateFormModel();
        if (isValid === true) {
            let postModel = Mapper.extractValuesFromModel(compressorModel);
            let saveData = {
                ProjectId: id,
                CompressorInformation: postModel,
                IncludeData: false
            }

            saveData.CompressorInformation.replacementPartList = replacementPartList;

            let method = compressorModel.id.value == 0 ? "post" : "put";

            API({
                url: `${cagUrl}/${id}/compressor/${compressorModel.id.value}`,
                method,
                data: saveData,
            }).then(() => {
                DirtyStore.setIsDirty(false);
                OpenGlobalSnackbar("success", t('SaveSuccessful'));
                if (goBack === true)
                    routeToCag();
                else if (goBack === false) {
                    if (cid == 0)
                        loadCleanPage();
                    history.push("/" + country + "/" + lng + '/project/' + id + '/CAG/AddEditCompressor/0');
                }
                else
                    copyForm();
            })
        }
        else {
            OpenGlobalSnackbar("danger", t('ModelInvalidError'));
        }
        return isValid;
    };

    const handleCancelClick = () => {
        routeToCag();
    };

    const routeToCag = () => {
        history.push("/" + country + "/" + lng + '/project/' + id + '/CAG#IndividualCompressorDetails');
    };

    const importCompressorModel = (importModel) => {
        compressorModel.manufacturer.value = importModel.manufacturer;
        compressorModel.modelSerialNumber.value = importModel.modelSerialNumber;
        compressorModel.compressorType.value = importModel.compressorType;
        compressorModel.controlType.value = importModel.controlType;
        compressorModel.nominalPower.value = importModel.nominalPower;
        compressorModel.freeAirDelivery.value = importModel.freeAirDelivery;
        compressorModel.nominalPressure.value = importModel.nominalPressure;
        setCompressorModel({ ...compressorModel });
        setCompressorSearchOpen(false);
    }

    return (
        <div className="edit-individual-compressor-information">
            <CompressorSearchDialog
                open={compressorSearchOpen}
                setOpen={setCompressorSearchOpen}
                importCompressorModel={importCompressorModel}
            />
            <Grid container spacing={3} direction="column">
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item id="#IndividualCompressorInformation">
                            <HeadingComponent value={t('IndividualCompressorInformation')} size="h6" />
                        </Grid>
                        <Grid item>
                            <ListPreviousNext />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MeasurementId
                        disabled={denyEdit}
                        element={compressorModel.measurementId}
                        updateState={(value) => { updateModelHandler("measurementId", value) }}
                        onChange={(value) => { updateModelHandler("measurementId", value) }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <DatePickerComponent
                                disabled={denyEdit}
                                t={t}
                                model={compressorModel.dateOfEntry}
                                onChange={e => updateModelHandler("dateOfEntry", e)}
                                maxDate={new Date()}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AutocompleteComponent disabled={denyEdit} options={executorUsersOptions} t={t} model={compressorModel.detectedBy} onInputChange={(e, value) => {
                                updateModelHandler("detectedBy", value);
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <CheckboxComponent model={compressorModel.isRepairDone} onChange={e => {
                        updateModelHandler("isRepairDone", e.target.checked);
                    }} />
                </Grid>
                {compressorModel && compressorModel.isRepairDone && compressorModel.isRepairDone.value && <Grid item>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <DatePickerComponent
                                t={t}
                                model={compressorModel.repairDate}
                                onChange={e => updateModelHandler("repairDate", e)}
                                maxDate={new Date()}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AutocompleteComponent options={executorUsersOptions} t={t} model={compressorModel.repairedBy} onInputChange={(e, value) => {
                                updateModelHandler("repairedBy", value);
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextareaAutosize
                                id='textarea-CAGEDIT-repairComment'
                                placeholder={t("Comment")}
                                width="100%"
                                minRows="5"
                                type="text"
                                value={compressorModel.repairComment.value}
                                onChange={e => updateModelHandler("repairComment", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>}
                <Grid item id="#Location">
                    <HeadingComponent value={t('Location')} size="h6" />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={compressorModel.compressorRoomNo} onChange={e => {
                                updateModelHandler("compressorRoomNo", e.target.value)
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container directio="row">
                        <Grid item id="#Details">
                            <HeadingComponent value={t('Details')} size="h6" />
                        </Grid>
                        {!denyEdit &&
                            <Grid item>
                                <Tooltip title={t("FindCompressorModel")}>
                                    <IconButton
                                        onClick={() => setCompressorSearchOpen(true)}
                                        id="btn_Compressor-Search"
                                        color="inherit"
                                        size="large"
                                    >
                                        <Search />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={compressorModel.manufacturer} onChange={e => {
                                updateModelHandler("manufacturer", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={compressorModel.modelSerialNumber} onChange={e => {
                                updateModelHandler("modelSerialNumber", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextFieldComponent disabled={denyEdit} t={t} model={compressorModel.year} onChange={e => {
                                updateModelHandler("year", e.target.value)
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SingleSelectComponent
                                disabled={denyEdit}
                                model={compressorModel.compressorType}
                                id="compressorType"
                                t={t}
                                listFromDb={CompressorTypes}
                                onChange={e => updateSelectModel("compressorType", e.target.value, CompressorTypes)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SingleSelectComponent
                                disabled={denyEdit}
                                model={compressorModel.controlType}
                                id="controlType"
                                t={t}
                                listFromDb={CompressorControlTypes}
                                onChange={e => updateSelectModel("controlType", e.target.value, CompressorControlTypes)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={compressorModel.nominalPower} onChange={e => {
                                updateModelHandler("nominalPower", InputRestrictions.Decimal(e.target.value))
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={compressorModel.freeAirDelivery} onChange={e => {
                                updateModelHandler("freeAirDelivery", InputRestrictions.Decimal(e.target.value, 2))
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={compressorModel.nominalPressure} onChange={e => {
                                updateModelHandler("nominalPressure", InputRestrictions.Decimal(e.target.value, 2))
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={compressorModel.setpointPressure} onChange={e => {
                                updateModelHandler("setpointPressure", InputRestrictions.Decimal(e.target.value, 2))
                            }} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePickerComponent
                                disabled={denyEdit}
                                t={t}
                                model={compressorModel.lastMaintenance}
                                onChange={e => updateModelHandler("lastMaintenance", e)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <CheckboxComponent disabled={denyEdit} model={compressorModel.heatRecovery} onChange={e => {
                        updateModelHandler("heatRecovery", e.target.checked);
                    }} />
                </Grid>
                <Grid item id="#WorkloadAirFlow">
                    <HeadingComponent value={t('WorkloadAirFlow')} size="h6" />
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={compressorModel.mondayToFridayWorkload} onChange={e => {
                                updateModelHandler("mondayToFridayWorkload", InputRestrictions.Decimal(e.target.value))
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={compressorModel.saturdayWorkload} onChange={e => { //need fix the mapping. sathurday maps to saturday
                                updateModelHandler("saturdayWorkload", InputRestrictions.Decimal(e.target.value))
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} md={6}>
                            <NumberInputFieldComponent disabled={denyEdit} t={t} model={compressorModel.sundayWorkload} onChange={e => {
                                updateModelHandler("sundayWorkload", InputRestrictions.Decimal(e.target.value))
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item id="#ReplacementParts">
                    <HeadingComponent value={t('ReplacementParts')} size="h6" />
                </Grid>
                <Grid item>
                    <ReplacementParts
                        setIsDirty={() => DirtyStore.setIsDirty(postForm)}
                        disabled={denyEdit}
                        replacementPartList={replacementPartList}
                        setReplacementPartList={setReplacementPartList}
                    />
                </Grid>
                <Grid item>
                    <HeadingComponent value={t('Comment')} size="h6" />
                </Grid>
                <Grid item>
                    <TextareaAutosize
                        disabled={denyEdit}
                        id='textarea-CAGEDIT-comment'
                        width="100%"
                        minRows="5"
                        type="text"
                        value={compressorModel.comment.value}
                        onChange={e => updateModelHandler("comment", e.target.value)}
                    />
                </Grid>
                {compressorModel.guid.value &&
                    <Grid item>
                        {compressorModel.guid.value && <ImageViewer groupId={ImageBlobConstants.IndividualCompressorPrefix + compressorModel.guid.value} />}
                    </Grid>
                }
                <Grid item>
                    <Grid container direction="row" justifyContent="flex-end">
                        <Grid item><ButtonComponent id='CAGEDIT-cancel' value={t('Cancel')} color="secondary" onChange={e => handleCancelClick(e)}></ButtonComponent></Grid>
                        <Grid item><ButtonComponent id='CAGEDIT-save' value={t('Save')} color="primary" onChange={() => postForm(true)}></ButtonComponent></Grid>
                        {!denyEdit && <Grid item><ButtonComponent id="CAGEDIT-SaveAndCopy" value={t('SaveAndCopy')} color="primary" onChange={() => postForm()}></ButtonComponent></Grid>}
                        {!denyEdit && <Grid item><ButtonComponent id='CAGEDIT-saveandnew' value={t('SaveAndNew')} color="primary" onChange={() => postForm(false)}></ButtonComponent></Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
