import React, { useState, useEffect } from 'react'
import { Grid, TableCell, TableRow, TableHead, TableBody, Table, TableContainer, Paper, IconButton, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from "react-router-dom";
import { API } from '../../../../helper/ApiHelper';
import { SimpleCheckbox } from '../../../Reusable';
import { OpenGlobalSnackbar } from '../../../../helper/GlobalVariables';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
// @ts-expect-error not converted yet
import TableHeadingContent from '../../../Reusable/TableHeadingContent/TableHeadingContent';
import { FilterModel } from "../../../Reusable/TableHeadingContent/GlobalFilterModel";
import { UserStore } from '../../../../store';
import { HasUserRole } from '../../../../helper/AuthorizationHelper/AuthHelper';
import * as Roles from '../../../../helper/AuthorizationHelper/AuthConstatants';
import { useGlobalState } from 'state-pool';
import { CountryParam, LanguageParam } from '../../../Types/Types';

interface IUserList {
    id: number;
    email: string;
    name?: string;
    lastName?: string;
    isEmailNotification: boolean;
    roles: IAssignedRole[];
    reducedRoles: string;
}

export interface IAssignedRole {
    id: number;
    userRole: string;
    executorId: number;
    executorName: string;
    projectId: number;
    projectName: string;
    isDefaultExecutor: boolean;
}

export default function UserManagement() {
    const usrUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "user-management"

    const { t } = useTranslation();
    const superAdmin = "SuperAdmin";
    const { country, lng } = useParams<CountryParam & LanguageParam>();
    const history = useHistory();
    const [mainModel, setMainModel] = useState<IUserList[]>([]);
    const [filterData, setFilterData] = useState<IUserList[]>([]);
    const [page, setPage] = useGlobalState("ManageUsersPage", { default: 0, persist: false });
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [checkedArray, setCheckedArray] = useState<number[]>([]);
    const [checkedArrayKey, setcheckedArrayKey] = useState<number[]>([]);
    const [denyEdit, setDenyEdit] = useState(true);
    useEffect(() => {
        loadExistingData();
        const userSub = UserStore.user.subscribe(user => {
            setDenyEdit(!HasUserRole([Roles.SuperAdmin], user));
        })
        return () => userSub.unsubscribe();
    }, []);


    const loadExistingData = () => {
        API.get(`${usrUrl}/users`).then((resp: IUserList[]) => {

            if (Array.isArray(resp) && resp.length > 0) {
                resp.forEach(item => {
                    if (Array.isArray(item.roles) && item.roles.length > 0)
                        item.reducedRoles = item.roles.reduce(
                            (acc, item) =>
                                acc +
                                (item.userRole == superAdmin ?
                                    t(superAdmin)
                                    :
                                    (item.executorName ?? "") + (item.projectName ?? "") + " - " + t(item.userRole)
                                )
                                + ";\n", ""
                        )
                })
            }

            setMainModel(resp)
            setFilterData(resp)
        });
    }

    const handleCheck = (checkId: number, checkIndex: number) => {
        const isAlready = checkedArray.indexOf(checkId);
        const isAlreadyKEY = checkedArray.indexOf(checkIndex);
        if (isAlready < 0) {
            checkedArray.push(checkId);
            checkedArrayKey.push(checkIndex);
        }
        else {
            checkedArray.splice(isAlready, 1);
            checkedArrayKey.splice(isAlreadyKEY, 1);
        }
        checkedArray.sort(function (a, b) { return b - a });
        checkedArrayKey.sort(function (a, b) { return b - a });
        setCheckedArray(checkedArray);
        setcheckedArrayKey(checkedArrayKey);
    }

    const deleteRequest = async () => {
        const dataUpload = {
            Ids: mainModel.filter((x) => checkedArray.some(c => c == x.id)).map(x => x.id)
        };
        API.post(`${usrUrl}/users/delete`, dataUpload).then(() => {
            setCheckedArray([]);
            loadExistingData();
            OpenGlobalSnackbar("success", t("DeleteSuccesfull"));
        })
    };


    const filterFunction = (tempdata: IUserList[]) => {
        setMainModel(tempdata);
        setPage(0);
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isCheckedForDeletion = (index: number) => {
        return checkedArray.some(x => x == index)
    }

    const handleEditExecutor = (id: number) => {
        history.push("/" + country + "/" + lng + '/SettingsPanel/UserManagement/add-edit/' + id);
    }
    return (
        <div className="user-management">
            <React.Fragment>
                <Grid item>
                    <TableHeadingContent
                        clickDelete={deleteRequest}
                        clickAdd={() => handleEditExecutor(0)}
                        defaultData={mainModel}
                        filterData={filterData}
                        onFilterFunction={filterFunction}
                        filterModel={FilterModel.UserManagement}
                        tableId={"UserManagement"}
                        denyEdit={denyEdit}
                    />
                </Grid>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{t("FirstName")}</TableCell>
                                <TableCell>{t("LastName")}</TableCell>
                                <TableCell>{t("Email")}</TableCell>
                                <TableCell>{t("IsEmailNotification")}</TableCell>
                                <TableCell>{t("Roles")}</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {mainModel.length > 0 &&
                                mainModel
                                    .slice(page * rowsPerPage, rowsPerPage + page * rowsPerPage)
                                    .map((item, index) => (
                                        <TableRow key={index + 1}>
                                            <TableCell>
                                                <SimpleCheckbox
                                                    id={"CAG-checkbox" + index}
                                                    key={Math.random()}
                                                    defaultChecked={isCheckedForDeletion(
                                                        page * rowsPerPage + index
                                                    )}
                                                    color="default"
                                                    onChange={() => handleCheck(item.id, index)}
                                                ></SimpleCheckbox>
                                            </TableCell>
                                            <TableCell id={"id" + index}>
                                                {item.name}
                                            </TableCell>
                                            <TableCell id={"id" + index}>
                                                {item.lastName}
                                            </TableCell>
                                            <TableCell id={"id" + index}>
                                                {item.email}
                                            </TableCell>
                                            <TableCell id={"id" + index}>
                                                {item.isEmailNotification ? <DoneIcon /> : <CloseIcon />}
                                            </TableCell>
                                            <TableCell id={"id" + index} className="prewrap">
                                                {item.reducedRoles}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    id={"btn_CAG-edit" + index}
                                                    onClick={() => {
                                                        handleEditExecutor(item.id);
                                                    }}
                                                    size="large"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                    component="div"
                    count={mainModel && mainModel.length > 0 ? mainModel.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </React.Fragment>
        </div>
    );
}
