import { UserRole } from "../../../../../helper/GlobalVariables";
// @ts-expect-error not converted yet
import { checkIsNotNullOrWhitespace, checkEmail, checkIsAnythingSelectedObject } from "../../../../../helper/Validator";
import { ModelParam, Translate } from "../../../../Types/Types";

export interface IExecutorUserModelFactory {
    id: ModelParam<number>,
    firstName: ModelParam<string>,
    lastName: ModelParam<string>,
    email: ModelParam<string>,
    role: ModelParam<UserRole | undefined>,
    isEmailNotification: ModelParam<boolean>,
    isDefaultExecutor: ModelParam<boolean>,
}

export interface IExecutorUser {
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    role: UserRole | undefined,
    isEmailNotification: boolean,
    isDefaultExecutor: boolean,
}


export const ExecutorUserModelFactory = (t: Translate): IExecutorUserModelFactory => ({
    id: { value: 0, isValid: true, validationError: "", validators: [], disabled: true, idkey: "", label: "" },
    firstName: { label: t("FirstName"), value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], idkey: "" },
    lastName: { label: t("LastName"), value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsNotNullOrWhitespace], idkey: "" },
    email: { label: t("Email"), value: "", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkEmail], idkey: "" },
    role: { label: t("UserRole"), value: undefined, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsAnythingSelectedObject], idkey: "" },
    isEmailNotification: { label: t("IsEmailNotification"), value: false, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [], idkey: "" },
    isDefaultExecutor: { label: t("IsDefaultExecutor"), value: false, isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [], idkey: "" }
})