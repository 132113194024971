import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Switch } from "react-router-dom";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import { useTranslation } from 'react-i18next';
import { Button, Grid, Container } from '@mui/material';
import { TranslationLanguages, UsedCountryList } from '../../helper/CountryLanguages/CountryLanguages';
import * as Roles from '../../helper/AuthorizationHelper/AuthConstatants';
import TopContentContainer from '../TopContentConainer/TopContentContainer';
import LandingPageView from '../LandingPageView/LandingPageView';
import Projects from './Projects/Projects';
import Requests from './Requests/Requests';
import "./ProjectsAndRequestsLists.scss";

export function ProjectsAndRequestsLists(props) {
    const history = useHistory();
    let { country, lng } = useParams();
    const { t } = useTranslation();

    const [isProjectListActive, setIsProjectListActive] = useState(true);

    useEffect(() => {
        props.handleSideBar(true);
    }, [])

    const clickRequestOverview = () => {
        history.push("/" + country + "/" + lng + "/projectsandrequests/requests");
    }

    const clickProjectOverview = () => {
        history.push("/" + country + "/" + lng + "/projectsandrequests/projects");
    }

    return (
        <div className="projects">
            <div className="projectMenu">
                <div className="projectMenu-body">
                    <Container>
                        <TopContentContainer ContentComponent={<LandingPageView />}></TopContentContainer>
                    </Container>
                </div>
                <Container>
                    <Grid container className="projectView-content">
                        <Grid item md={2} className="projectView-left">
                            <Grid item>
                                <Button
                                    className={`projectView-button ${isProjectListActive === false ? "borderChange" : ""}`}
                                    id="btn_Project-RequestButton"
                                    onClick={clickRequestOverview}
                                >
                                    {t('RequestOverview')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    className={`projectView-button ${isProjectListActive === true ? "borderChange" : ""}`}
                                    id="btn_Project-OverviewButton"
                                    onClick={clickProjectOverview}
                                >
                                    {t('ProjectOverview')}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item md={10}>
                            <Switch>
                                <PrivateRoute
                                    path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/projectsandrequests/requests`}
                                    component={Requests}
                                    allowedRoles={[Roles.SuperAdmin, Roles.ExecutorAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                                    setIsProjectListActive={setIsProjectListActive}
                                />
                                <PrivateRoute
                                    path={[`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/projectsandrequests/projects`, `/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/projectsandrequests`]}
                                    component={Projects}
                                    allowedRoles={[Roles.SuperAdmin, Roles.ExecutorAdmin, Roles.Technician, Roles.Reader, Roles.Editor]}
                                    setIsProjectListActive={setIsProjectListActive}
                                />
                            </Switch>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div >
    );

}
