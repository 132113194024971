import React from 'react';
import { styled } from '@mui/material';

interface ISpinner {
    open: boolean
}

const LoadingSpinner = styled('div')((props: ISpinner) => ({
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1500,
    display: props.open ? 'unset' : 'none',
    "@keyframes pulse-outer": (
        {
            "from": {
                transform: "scale(1)"
            },
            "to": {
                transform: "scale(0.5)"
            }
        }
    ),
}));
const LoadingSpinnerOverlay = styled('div')({
    width: '100%',
    height: '100%',
    background: '#000',
    opacity: .75
});
const LoadingSpinnerContainer = styled('div')({
    display: 'inline - block',
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: 0,
    marginTop: '-48px',
    textAlign: 'center'
});
const LoadingSpinnerCircleContainer = styled('div')({
    display: 'inline-block',
    width: '100%',
    height: '96px',
    position: 'relative',
    '&>div': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        left: '50%',
        background: '#0091dc',
        borderRadius: '100%',
        animationDuration: 'infinite',
        WebkitAnimationDuration: 'infinite',
        animationDirection: 'alternate',
        WebkitAnimationDirection: 'alternate',
        animationTimingFunction: 'ease-in-out',
        WebkitAnimationTimingFunction: 'ease-in-out',
    }
});
const LoadingSpinnerCircleOuter = styled('div')({
    width: '96px',
    height: '96px',
    marginTop: '-48px',
    marginLeft: '-48px',
    zIndex: 1,
    opacity: .15,
    animation: `pulse-outer 1000ms`,
});
const LoadingSpinnerCircleCenter = styled('div')({
    width: '64px',
    height: '64px',
    marginTop: '-32px',
    marginLeft: '-32px',
    zIndex: 2,
    opacity: .3,
    animationName: 'pulse-center',
    WebkitAnimationName: 'pulse-center',
    animationDuration: '100ms',
    WebkitAnimationDuration: '100ms',
});
const LoadingSpinnerCircleInner = styled('div')({
    width: '32px',
    height: '32px',
    marginTop: '-16px',
    marginLeft: '-16px',
    zIndex: 3,
    opacity: 1,
    animationName: 'pulse-inner',
    WebkitAnimationName: 'pulse-inner',
    animationDuration: '200ms',
    WebkitAnimationDuration: '200ms',
});
const LoadingSpinnerText = styled('div')({
    display: 'inline-block',
    maxWidth: '50%',
    fontSize: '16px',
    textAlign: 'center',
    color: '#fff'
});

export default function Spinner(props: ISpinner) {
    return (
        <LoadingSpinner {...props}
            className={"loader-animation"}
            data-ajax-hide-delay="150"
            data-ajax-show-delay="250">
            <LoadingSpinnerOverlay />
            <LoadingSpinnerContainer>
                <LoadingSpinnerCircleContainer>
                    <LoadingSpinnerCircleOuter />
                    <LoadingSpinnerCircleCenter />
                    <LoadingSpinnerCircleInner />
                </LoadingSpinnerCircleContainer>
                <LoadingSpinnerText>Loading...</LoadingSpinnerText>
            </LoadingSpinnerContainer>
        </LoadingSpinner>
    );
}