import { format, parseISO } from "date-fns";

export const september16 = new Date(2023, 8, 16);

export const LocaleDateFormat = new Date(1999, 1, 1).toLocaleDateString().replace("1999", "yyyy").replace("99", "yy").replace("01", "MM").replace("1", "MM").replace("02", "dd").replace("2", "dd");

export const FormatDate = (dateString: string | undefined | Date) => {
    if (dateString != undefined && format != undefined && LocaleDateFormat != undefined) {
        let date;
        try {
            date = format(parseISO(dateString.toString()), LocaleDateFormat);
        } catch (e) {
            date = ""
        }
        return date;
    }
    else {
        return "";
    }
}

export const toLocalDateString = (date: any) => date ? new Intl.DateTimeFormat("default", { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(date)) : " ";

export const newUTC = (date: Date) => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

export const nowUTC = () => newUTC(new Date())

export const nowLocal = () => new Date()