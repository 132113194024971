// @ts-expect-error not converted yet
import { checkIsAnythingSelected } from "../../../../../helper/Validator";
import { IDropdownItem, ModelParam, Translate } from "../../../../Types/Types";

export interface ILicenseRequestModelFactory {
    executorReceiverIsDifferent: ModelParam<boolean>;
    executorReceiverId: ModelParam<IDropdownItem | null>;
    customerPurchaseOrderNumber: ModelParam<string>;
    license: ModelParam<IDropdownItem| null>;
    quantity: ModelParam<IDropdownItem| null>;
}

export const LicenseRequestModel = (t: Translate): ILicenseRequestModelFactory => ({
    executorReceiverIsDifferent: { label: t("ExecutorReceiverIsDifferent"), idkey: "LicenseRequest-ExecutorReceiverIsDifferent", value: false, isValid: true, validationError: "", validators: [] },
    executorReceiverId: { value: null, label: t("LicenseReceiverExecutor"), idkey: "LicenseRequest-LicenseReceiverExecutor", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [isExecutorReceiverIdFilled] },
    customerPurchaseOrderNumber: { value: "", label: t("CustomerPurchaseOrderNumber"), idkey: "LicenseRequest-customerPurchaseOrderNumber", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [] },
    license: { value: null, label: t("MaterialNr"), idkey: "LicenseRequest-license", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsAnythingSelected] },
    quantity: { value: null, label: t("QuantityToBeInvoiced"), idkey: "LicenseRequest-quantity", isValid: true, validationError: t("PleaseVerifyYourEntriesError"), validators: [checkIsAnythingSelected] }
})

export const LicenseSelections = (t: Translate) => ([
    { id: 1, value: t("StandardLeakageDetection") },
    { id: 2, value: t("StandardFullAudit") },
    { id: 3, value: t("ProfessionalLeakageDetection") },
    { id: 4, value: t("ProfessionalFullAudit") }
]);

export const LicenseQuantitySelections = (t: Translate) => ([
    { id: 1, value: t("OneProjectLicenseOrSubscription") },
    { id: 5, value: t("FiveProjectLicenses") },
    { id: 10, value: t("TenProjectLicensesPlusGratis") }
]);

const isExecutorReceiverIdFilled = (value: IDropdownItem | null, model: ILicenseRequestModelFactory | void) => model?.executorReceiverIsDifferent.value === false || checkIsAnythingSelected(value);