export const sortArray = (myArray, field, sortAsc, isModel = false) => {
    myArray.sort((a, b) => {
        if (isModel) {
            a = getFieldValue(a[field]);
            b = getFieldValue(b[field]);
        }
        let aVal = a ? typeof a == "number" ? a : a.toString().toLowerCase() : "";
        let bVal = b ? typeof b == "number" ? b : b.toString().toLowerCase() : "";
        if ((aVal < bVal && sortAsc) || (aVal > bVal && !sortAsc)) return -1;
        if ((aVal > bVal && sortAsc) || (aVal < bVal && !sortAsc)) return 1;
        return 0;
    });

    return myArray;
}

const getFieldValue = (x) => {
    return x?.value?.value?.value ?? x?.value?.value ?? x?.value ?? x;
}

export const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}