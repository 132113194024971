import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { HeadingComponent, ButtonComponent } from '../../components/Reusable/';
import { Close } from '@mui/icons-material';
import * as Consts from '../../helper/ConstantRepository';
import ImportProjectView from './ImportProjectView';
import ImportFullDryer from './ImportFullDryer';
import ImportFullCompressor from './ImportFullCompressor';
import { OpenGlobalSnackbar } from '../../helper/GlobalVariables';
import { ExecutorStore } from '../../store/ExecutorStore';
import { API } from '../../helper/ApiHelper';

export default function ImportFullDialog(props) {
    const importUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "import";
    const { open, setOpen, viewList, generalInformation, inquiry, reloadData } = props;
    let { id } = useParams();
    const { t } = useTranslation();
    const views = [Consts.ProjectImport, ...viewList, Consts.ImportConfirmation];

    const [executorId, setExecutorId] = useState(0);
    const [projectId, setProjectId] = useState(null);
    const [currentView, setCurrentView] = useState(Consts.ProjectImport);
    const [selectedDryerIds, setSelectedDryerIds] = useState([]);
    const [selectedCompressorIds, setSelectedCompressorIds] = useState([]);

    useEffect(() => {
        if (open === true) {
            setProjectId(null);
            setCurrentView(Consts.ProjectImport);
            resetValues();
        }
    }, [open]);

    useEffect(() => {
        let execSub = ExecutorStore.executorSelection.subscribe(id => {
            setExecutorId(id);
        })
        return () => {
            execSub.unsubscribe();
        }
    }, [])

    const resetValues = () => {
        setSelectedDryerIds([]);
        setSelectedCompressorIds([]);
    }

    const nextView = () => {
        let currentViewIndex = views.indexOf(currentView);
        setCurrentView(views[++currentViewIndex]);
    }

    const prevView = () => {
        let currentViewIndex = views.indexOf(currentView);
        if (currentViewIndex == 0) {
            setOpen(false);
            return
        }
        setCurrentView(views[--currentViewIndex]);
    }

    const pickProject = (index) => {
        resetValues();
        setProjectId(index);
        nextView();
    }

    const importElements = async () => {
        if (selectedDryerIds.length == 0 && selectedCompressorIds.length == 0 && !generalInformation && !inquiry) {
            OpenGlobalSnackbar("danger", t('PleaseSelectAtleastOneEntry'));
        }
        else {
            let postObject = { SourceProjectId: projectId, DryerIds: selectedDryerIds, CompressorIds: selectedCompressorIds, ImportGeneralInformation: generalInformation, ExecutorId: executorId};
            const result = await API.post(importUrl + (inquiry ? "/inquiry/" : "/dryer-compressor-full/") + (id === undefined ? -1 : id), postObject);
            OpenGlobalSnackbar("success", t('ImportSuccessful'));
            reloadData(id === undefined ? result : undefined);
            setOpen(false);
        }
    }

    return (
        <Dialog maxWidth={currentView === Consts.ImportConfirmation ? 'sm' : 'xl'} open={open}>
            <DialogTitle>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <HeadingComponent value={t("ImportDialog")} size="h6" />
                    </Grid>
                    <Grid item>
                        <IconButton color="inherit" onClick={() => setOpen(false)} size="large">
                            <Close fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                {currentView === Consts.ProjectImport && <ImportProjectView open={open} pickProject={pickProject} />}
                {currentView === Consts.ProjectImportDryer && <ImportFullDryer projectId={projectId} selectedIds={selectedDryerIds} setSelectedIds={setSelectedDryerIds} />}
                {currentView === Consts.ProjectImportCompressor && <ImportFullCompressor projectId={projectId} selectedIds={selectedCompressorIds} setSelectedIds={setSelectedCompressorIds} />}
                {currentView === Consts.ImportConfirmation &&
                    <Grid item>
                        <HeadingComponent value={t('Warning')} size="h6" />
                        <Typography gutterBottom>{t("ImportingOverrideWarning")}</Typography>
                    </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                        <Grid container direction="row">
                            <Grid item><ButtonComponent id="Back" value={t('Back')} color="primary" onChange={prevView}></ButtonComponent></Grid>
                            <Grid item><ButtonComponent id="Cancel" value={t('Cancel')} color="secondary" onChange={() => setOpen(false)}></ButtonComponent></Grid>
                        </Grid>
                    </Grid>
                    {views.indexOf(currentView) < views.length - 2 && projectId != null &&
                        <Grid item><ButtonComponent id="Next" value={t('Next')} color="primary" onChange={nextView}></ButtonComponent></Grid>
                    }
                    {views.indexOf(currentView) >= views.length - 2 &&
                        <Grid item><ButtonComponent id="Import" value={t('Import')} color="primary" onChange={views.indexOf(currentView) == views.length - 1 ? importElements : nextView}></ButtonComponent></Grid>
                    }
                </Grid>
            </DialogActions>
        </Dialog>
    );
}