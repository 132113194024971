// @ts-expect-error not converted yet
import { ProjectsAndRequestsLists } from "./components/ProjectsAndRequestsLists/ProjectsAndRequestsLists";
// @ts-expect-error not converted yet
import { Project } from "./components/Project/Project";
// @ts-expect-error not converted yet
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { MainHeader } from "./components/MainHeader/MainHeader";
// @ts-expect-error not converted yet
import ReplacementPartsCart from "./components/ReplacementPartsCart/ReplacementPartsCart";
import SettingsPanel from "./components/Settings/SettingsPanel";
// @ts-expect-error not converted yet
import QrCodeAudit from "./components/QrCodeAudit/QrCodeAudit";
// @ts-expect-error not converted yet
import ScrollComponent from "./components/base/ScrollComponent";
import * as Roles from './helper/AuthorizationHelper/AuthConstatants';
import { ErrorNotification } from "./helper/ApiInterceptorHelper";
// @ts-expect-error not converted yet
import { GlobalSpinner } from "./helper/SpinnerHelper";
import Unsubscribe from "./components/Unsubscribe/Unsubscribe";
import Maintenance from "./components/Maintenance/Maintenance";
import { Inquiry } from "./components/Inquiry/Inquiry";
import { parseRouteLngToLocale } from './components/Reusable/MaterialUIStyles/StyleLocales';
import CountryLanguageSelection from "./components/CountryLanguageSelection/CountryLanguageSelection";
import { FestchDropDownLists } from './helper/GlobalVariables'
import { TranslationLanguages, UsedCountryList } from './helper/CountryLanguages/CountryLanguages';
import InitializeMessage from './components/InitializeMessage/InitializeMessage'
import SideWrapper from "./components/base/SideWrapper";
import { ErrorPage } from "./components/base/ErrorPage";
import { ThankYou } from "./components/base/ThankYou";
import { Footer } from "./components/base/Footer";
import { Home } from "./components/Home/Home";
import React, { useState, useEffect } from "react";
import { Switch, Route, useParams } from "react-router-dom";
import { SnackbarComponent } from './components/Reusable';
import { theme } from './components/Reusable/MaterialUIStyles/Styles';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material';
import { useGlobalState } from 'state-pool';
import axios from 'axios';
import "./styles.scss";
import { LanguageParam } from "./components/Types/Types";

export default function App() {
    const maintenanceFlagUrl = '/maintenance/maintenanceFlag.txt';
    const [isMaintenance, setIsMaintenance] = useState(false);
    const [initDataFetched, setInitFetch] = useState(false);
    const [initLanguage] = useGlobalState("InitLanguage", { default: false, persist: true });

    useEffect(() => {
        checkMaintenanceStatus();
    }, []);

    const isSiteOnMaintenance = async () => {
        try {
            const res = await axios.get(maintenanceFlagUrl);
            const isMaint = res.status === 200;
            return isMaint;
        } catch (e) {
            return false;
        }
    }

    const checkMaintenanceStatus = async () => {
        const isMaint = await isSiteOnMaintenance();
        if (isMaint) {
            setIsMaintenance(true);
        } else {
            await FestchDropDownLists();
            setInitFetch(true);
        }
    }

    return (
        <React.Fragment>
            {isMaintenance ?
                <Maintenance />
                :
                <Route
                    path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?`}
                    component={BaseContent}
                />
            }
        </React.Fragment>
    );

    function BaseContent() {
        const { lng } = useParams<LanguageParam>();
        const [themeWithLocale, setThemeWithLocale] = useState(theme);

        useEffect(() => {
            setThemeWithLocale(createTheme(theme, parseRouteLngToLocale(lng)))
        }, [lng]);

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themeWithLocale}>
                    <React.Fragment>
                        <SnackbarComponent isGlobal={true} />
                        <GlobalSpinner />
                        <ErrorNotification />
                        <ScrollComponent />
                        <SideWrapper>
                            {(props) => (
                                <>
                                    <MainHeader {...props}/>
                                    {initDataFetched ?
                                        (
                                            <Page
                                                handleSideBar={props.handleSideBar}
                                                showContent={props.showContent}
                                                show={props.show}
                                            />
                                        ) :
                                        (
                                            <InitializeMessage title="InitializingDataMessage" />
                                        )}
                                    <Footer />
                                </>
                            )}
                        </SideWrapper>
                    </React.Fragment>
                </ThemeProvider>
            </StyledEngineProvider>
        )
    }

    interface IProps {
        handleSideBar: (show: boolean, iconHide?: boolean) => void;
        showContent?: boolean;
        show: boolean;
    }

    function Page(props: IProps) {
        const { handleSideBar, showContent } = props;

        return (
            <div className="content-max-height">
                {
                    initLanguage === true ?
                        <CountryLanguageSelection />
                        :
                        <Switch>
                            <Route
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/unsubscribe/:email`}
                                component={Unsubscribe}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/order`}
                                component={ReplacementPartsCart}
                                handleSideBar={handleSideBar}
                                allowedRoles={[Roles.SuperAdmin, Roles.Technician, Roles.ExecutorAdmin, Roles.Reader, Roles.Editor]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?/validate`}
                                component={QrCodeAudit}
                                handleSideBar={handleSideBar}
                                allowedRoles={[Roles.SuperAdmin, Roles.Technician, Roles.ExecutorAdmin]}
                            />
                            <PrivateRoute
                                exact
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/projectsandrequests/:anypath?`}
                                component={ProjectsAndRequestsLists}
                                handleSideBar={handleSideBar}
                            />
                            <Route
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/contact`}
                                render={(props) =>
                                    <Inquiry {...props}
                                        handleSideBar={handleSideBar}
                                    />}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/SettingsPanel`}
                                component={SettingsPanel}
                                handleSideBar={handleSideBar}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/request/:id?`}
                                component={Inquiry}
                                handleSideBar={handleSideBar}
                                allowedRoles={[Roles.SuperAdmin, Roles.Technician, Roles.ExecutorAdmin]}
                            />
                            <PrivateRoute
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/project/:id?`}
                                component={Project}
                                handleSideBar={handleSideBar}
                                showContent={showContent}
                                allowedRoles={[Roles.SuperAdmin, Roles.Technician, Roles.ExecutorAdmin, Roles.Reader, Roles.Editor]}
                            />
                            <Route
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/ThankYou`}
                                component={ThankYou}
                            />
                            <Route
                                exact
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?`}
                                render={(props) =>
                                    <Home {...props}
                                        handleSideBar={handleSideBar}
                                    />}
                            />
                            <Route
                                path={`/:country(${UsedCountryList})?/:lng(${TranslationLanguages})?/error-page/:errorCode?/:errorMessage?`}
                                render={({ match }) =>
                                    <ErrorPage
                                        errorCode={match.params.errorCode ?? "404"}
                                        errorMessage={match.params.errorMessage ?? "PageNotFoundError"}
                                    />}
                            />
                            <Route
                                path="*"
                                render={() =>
                                    <ErrorPage errorCode={"404"} errorMessage={"PageNotFoundError"}
                                    />}
                            />
                        </Switch>
                }
            </div>
        );
    }
}