import { BehaviorSubject } from "rxjs";
import { ExecutorStore } from './ExecutorStore';
import { API } from '../helper/ApiHelper';
import { authContext, adalConfig } from '../adalConfig';

export interface IUser {
    id?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    accountType?: string;
    userRights?: IUserRights;
}

export interface IUserRights {
    isSuperAdmin?: boolean;
    isTechnician?: boolean;
    isReader?: boolean;
    isEditor?: boolean;
    isExecutorAdmin?: boolean;
}

const user = new BehaviorSubject<IUser>({});
const token = new BehaviorSubject<string>("");
const tokenExpiryDate = new BehaviorSubject(Date.now());
const userLoggedIn = new BehaviorSubject(false);
const uumUrl = process.env.REACT_APP_DEVFESSSERVICE_BASE + "uum/users";
const uumMigration = process.env.REACT_APP_UUM_MIGRATION as string;
let adalTimeout: NodeJS.Timeout | string | number | undefined = undefined;
let adalExpiryCheckTimeout: NodeJS.Timeout | string | number | undefined = undefined;

const getTokenFromCache = () => authContext.getCachedToken(adalConfig.clientId);

const setTokenRefresh = () => {
    clearTimeout(adalExpiryCheckTimeout);
    adalExpiryCheckTimeout = setTimeout(setTokenRefresh, 5000);

    if (!document.hidden && token.value != undefined && token.value.length > 0 && Date.now() > tokenExpiryDate.value)
        checkAdal();
}

const checkAdal = () => {
    clearTimeout(adalTimeout);
    adalTimeout = setTimeout(checkAdal, 3000);
    const tokenFromCache = getTokenFromCache();
    const isTokenStillValid = tokenFromCache != undefined && (JSON.parse(atob(tokenFromCache.split('.')[1]))).exp * 1000 > Date.now();
    if (isTokenStillValid) {
        clearTimeout(adalTimeout);
        authContext.acquireToken(
            process.env.REACT_APP_CLIENT_ID as string,
            () => {
                setToken()
            }
        );
    }
    else if (!authContext.loginInProgress() && !document.hidden) {
        authContext.login();
    }
}

const setToken = () => {
    const tok = sessionStorage.getItem('adal.idtoken');
    if (tok != undefined) {
        token.next(tok);
        const tokenExpirationDate = (JSON.parse(atob(tok.split('.')[1]))).exp * 1000;
        tokenExpiryDate.next(tokenExpirationDate);
        initializeUser();
        setTokenRefresh();
    }
}

const initializeUser = () => {
    if (userLoggedIn.value == false) {
        UserStore.initUser();
        userLoggedIn.next(true);
    }
}

const UserStore = {
    user,
    token,
    userLoggedIn,
    loginUser: () => {
        if (token.value != undefined && token.value.length > 0) {
            initializeUser();
        }
        else {
            clearTimeout(adalTimeout);
            adalTimeout = setTimeout(checkAdal, 1000);
        }
    },
    setUser: (resp: IUser) => {
        user.next(resp);
        ExecutorStore.initExecutorList();
    },
    initUser: function () {
        API.get<IUser>(uumUrl).then((resp) => {
            this.setUser(resp);
        }).catch((error) => {
            if (!!error.response && error.response.status == 428) {
                window.location.href = uumMigration;
            }
            else
                throw error;
        });
    }
}

export {
    UserStore,
    checkAdal
}