import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Link, styled } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { GenerateBreadcrumbsFromPath, PathKeyNamePairs, GetRoutingLink } from "../../helper/BreadCrumbHelper/BreadCrumbHelper"
import './Breadcrumbs.scss';

const StyledBreadcrumbs = styled(Breadcrumbs)({
    '& .MuiBreadcrumbs-li': {
        cursor: 'pointer'
    }
});

export default function BreadcrumbsComponent({ showOnlyHome }) {
    const { t } = useTranslation();
    let { country, lng } = useParams();
    const history = useHistory();
    const location = useLocation();
    const [breadCrumbs, setBreadCrumbs] = useState([]);

    useEffect(() => {
        setBreadCrumbs(GenerateBreadcrumbsFromPath(location.pathname));
    }, [location.pathname]);


    return (
        <StyledBreadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
            className={'breadcrumbs'}>
            <Link
                color="inherit"
                href="/"
                onClick={e => { e.preventDefault(); history.push("/" + country + "/" + lng + "/"); }}
                id="lnk_bread-Home"
                underline="hover"
            >
                Home
            </Link>
            {showOnlyHome ? <span className="last_breadcrumb">Fess Contact</span>
                :
                breadCrumbs.map((p, i) => {
                    return (
                        <Link
                            key={`breadcrumb${i}`}
                            color="inherit"
                            className={breadCrumbs[breadCrumbs.length - 1] == p ? "last_breadcrumb" : null}
                            href={GetRoutingLink(p, location.pathname, history)}
                            onClick={e => { e.preventDefault(); history.push(GetRoutingLink(p, location.pathname, history)); }}
                            id={`lnk_bread-${p}`}
                            underline="hover"
                        >
                            {p in PathKeyNamePairs ? t(PathKeyNamePairs[p]) : t(p)}
                        </Link>
                    );
                })
            }
        </StyledBreadcrumbs>
    );
}